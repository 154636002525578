import _descriptors from "../internals/descriptors";
import _global3 from "../internals/global";
import _functionUncurryThis from "../internals/function-uncurry-this";
import _isForced from "../internals/is-forced";
import _inheritIfRequired from "../internals/inherit-if-required";
import _createNonEnumerableProperty from "../internals/create-non-enumerable-property";
import _objectGetOwnPropertyNames from "../internals/object-get-own-property-names";
import _objectIsPrototypeOf from "../internals/object-is-prototype-of";
import _isRegexp from "../internals/is-regexp";
import _toString from "../internals/to-string";
import _regexpGetFlags from "../internals/regexp-get-flags";
import _regexpStickyHelpers from "../internals/regexp-sticky-helpers";
import _proxyAccessor from "../internals/proxy-accessor";
import _defineBuiltIn from "../internals/define-built-in";
import _fails from "../internals/fails";
import _hasOwnProperty from "../internals/has-own-property";
import _internalState from "../internals/internal-state";
import _setSpecies from "../internals/set-species";
import _wellKnownSymbol from "../internals/well-known-symbol";
import _regexpUnsupportedDotAll from "../internals/regexp-unsupported-dot-all";
import _regexpUnsupportedNcg from "../internals/regexp-unsupported-ncg";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var DESCRIPTORS = _descriptors;
var _global2 = _global3;
var uncurryThis = _functionUncurryThis;
var isForced = _isForced;
var inheritIfRequired = _inheritIfRequired;
var createNonEnumerableProperty = _createNonEnumerableProperty;
var getOwnPropertyNames = _objectGetOwnPropertyNames.f;
var isPrototypeOf = _objectIsPrototypeOf;
var isRegExp = _isRegexp;
var toString = _toString;
var getRegExpFlags = _regexpGetFlags;
var stickyHelpers = _regexpStickyHelpers;
var proxyAccessor = _proxyAccessor;
var defineBuiltIn = _defineBuiltIn;
var fails = _fails;
var hasOwn = _hasOwnProperty;
var enforceInternalState = _internalState.enforce;
var setSpecies = _setSpecies;
var wellKnownSymbol = _wellKnownSymbol;
var UNSUPPORTED_DOT_ALL = _regexpUnsupportedDotAll;
var UNSUPPORTED_NCG = _regexpUnsupportedNcg;
var MATCH = wellKnownSymbol("match");
var NativeRegExp = _global2.RegExp;
var RegExpPrototype = NativeRegExp.prototype;
var SyntaxError = _global2.SyntaxError;
var exec = uncurryThis(RegExpPrototype.exec);
var charAt = uncurryThis("".charAt);
var replace = uncurryThis("".replace);
var stringIndexOf = uncurryThis("".indexOf);
var stringSlice = uncurryThis("".slice); // TODO: Use only propper RegExpIdentifierName

var IS_NCG = /^\?<[^\s\d!#%&*+<=>@^][^\s!#%&*+<=>@^]*>/;
var re1 = /a/g;
var re2 = /a/g; // "new" should create a new object, old webkit bug

var CORRECT_NEW = new NativeRegExp(re1) !== re1;
var MISSED_STICKY = stickyHelpers.MISSED_STICKY;
var UNSUPPORTED_Y = stickyHelpers.UNSUPPORTED_Y;
var BASE_FORCED = DESCRIPTORS && (!CORRECT_NEW || MISSED_STICKY || UNSUPPORTED_DOT_ALL || UNSUPPORTED_NCG || fails(function () {
  re2[MATCH] = false; // RegExp constructor can alter flags and IsRegExp works correct with @@match

  return NativeRegExp(re1) != re1 || NativeRegExp(re2) == re2 || NativeRegExp(re1, "i") != "/a/i";
}));

var handleDotAll = function (string) {
  var length = string.length;
  var index = 0;
  var result = "";
  var brackets = false;
  var chr;

  for (; index <= length; index++) {
    chr = charAt(string, index);

    if (chr === "\\") {
      result += chr + charAt(string, ++index);
      continue;
    }

    if (!brackets && chr === ".") {
      result += "[\\s\\S]";
    } else {
      if (chr === "[") {
        brackets = true;
      } else if (chr === "]") {
        brackets = false;
      }

      result += chr;
    }
  }

  return result;
};

var handleNCG = function (string) {
  var length = string.length;
  var index = 0;
  var result = "";
  var named = [];
  var names = {};
  var brackets = false;
  var ncg = false;
  var groupid = 0;
  var groupname = "";
  var chr;

  for (; index <= length; index++) {
    chr = charAt(string, index);

    if (chr === "\\") {
      chr = chr + charAt(string, ++index);
    } else if (chr === "]") {
      brackets = false;
    } else if (!brackets) switch (true) {
      case chr === "[":
        brackets = true;
        break;

      case chr === "(":
        if (exec(IS_NCG, stringSlice(string, index + 1))) {
          index += 2;
          ncg = true;
        }

        result += chr;
        groupid++;
        continue;

      case chr === ">" && ncg:
        if (groupname === "" || hasOwn(names, groupname)) {
          throw new SyntaxError("Invalid capture group name");
        }

        names[groupname] = true;
        named[named.length] = [groupname, groupid];
        ncg = false;
        groupname = "";
        continue;
    }

    if (ncg) groupname += chr;else result += chr;
  }

  return [result, named];
}; // `RegExp` constructor
// https://tc39.es/ecma262/#sec-regexp-constructor


if (isForced("RegExp", BASE_FORCED)) {
  var RegExpWrapper = function RegExp(pattern, flags) {
    var thisIsRegExp = isPrototypeOf(RegExpPrototype, this || _global);
    var patternIsRegExp = isRegExp(pattern);
    var flagsAreUndefined = flags === undefined;
    var groups = [];
    var rawPattern = pattern;
    var rawFlags, dotAll, sticky, handled, result, state;

    if (!thisIsRegExp && patternIsRegExp && flagsAreUndefined && pattern.constructor === RegExpWrapper) {
      return pattern;
    }

    if (patternIsRegExp || isPrototypeOf(RegExpPrototype, pattern)) {
      pattern = pattern.source;
      if (flagsAreUndefined) flags = getRegExpFlags(rawPattern);
    }

    pattern = pattern === undefined ? "" : toString(pattern);
    flags = flags === undefined ? "" : toString(flags);
    rawPattern = pattern;

    if (UNSUPPORTED_DOT_ALL && "dotAll" in re1) {
      dotAll = !!flags && stringIndexOf(flags, "s") > -1;
      if (dotAll) flags = replace(flags, /s/g, "");
    }

    rawFlags = flags;

    if (MISSED_STICKY && "sticky" in re1) {
      sticky = !!flags && stringIndexOf(flags, "y") > -1;
      if (sticky && UNSUPPORTED_Y) flags = replace(flags, /y/g, "");
    }

    if (UNSUPPORTED_NCG) {
      handled = handleNCG(pattern);
      pattern = handled[0];
      groups = handled[1];
    }

    result = inheritIfRequired(NativeRegExp(pattern, flags), thisIsRegExp ? this || _global : RegExpPrototype, RegExpWrapper);

    if (dotAll || sticky || groups.length) {
      state = enforceInternalState(result);

      if (dotAll) {
        state.dotAll = true;
        state.raw = RegExpWrapper(handleDotAll(pattern), rawFlags);
      }

      if (sticky) state.sticky = true;
      if (groups.length) state.groups = groups;
    }

    if (pattern !== rawPattern) try {
      // fails in old engines, but we have no alternatives for unsupported regex syntax
      createNonEnumerableProperty(result, "source", rawPattern === "" ? "(?:)" : rawPattern);
    } catch (error) {
      /* empty */
    }
    return result;
  };

  for (var keys = getOwnPropertyNames(NativeRegExp), index = 0; keys.length > index;) {
    proxyAccessor(RegExpWrapper, NativeRegExp, keys[index++]);
  }

  RegExpPrototype.constructor = RegExpWrapper;
  RegExpWrapper.prototype = RegExpPrototype;
  defineBuiltIn(_global2, "RegExp", RegExpWrapper);
} // https://tc39.es/ecma262/#sec-get-regexp-@@species


setSpecies("RegExp");
export default {};